import BN from 'bn.js'

export const MAX_UINT = new BN('115792089237316195423570985008687907853269984665640564039457584007913129639935')

export const CRAFTER_CONTRACTS: any = {
  1: '',
  42: '',
  250: '',
}

export const OLD_CRAFTER_CONTRACTS: any = {
  1: '',
  42: '',
  250: '',
}

export const GEM_CONTRACTS: any = {
  1: '',
  42: '',
  250: '0x342EbF0A5ceC4404CcFF73a40f9c30288Fc72611',
}

export const OLD_GEM_CONTRACTS: any = {
  1: '',
  42: '',
  250: '',
}

export const LOOT_CONTRACTS: any = {
  1: '',
  42: '',
  250: '',
}

export const EXPLORERS: any = {
  1: 'https://etherscan.io/',
  42: 'https://kovan.etherscan.io/',
  250: 'https://ftmscan.com/',
}

export const GEM_COUNT = 12

export const GEM_NAMES = [
  'Turquoise',
  'Pearl',
  'Zircon',
  'Moonstone',
  'Amber',
  'Spinel',
  'White Opal',
  'Violet Garnet',
  'Phoenix Feather',
  'Yggdrasil Seed',
  'Ocean Heart',
  "Philosopher's Stone",
]

export const GEMS_PER_MINES = [64, 32, 16, 8, 4, 2, 1, 1, 1, 1, 1, 1]

export const GEM_COLORS = [
  '#30D5C8',
  '#EAE0C8',
  '#007082',
  '#A3C6C2',
  '#FFBF00',
  '#034B03',
  '#7FFFD4',
  '#872282',
  '#F26722',
  '#21B54B',
  '#1E88B4',
  '#660000',
]

export const WRAPPED_GEM_CONTRACTS = [
  '0xBCD97F87B3E36028BdC35b573C84230fCa7fAf24',
  '0xC83a2c5f417b619EaB1fA62F76B02efA76973655',
  '0x33BE3A1dF0E2f4124e76ee829Adf7FF53A95BCa6',
  '0xD7E282Fd6f7b9C821ad36b24Ae2547Ff059619E6',
  '0x5B115563844eC6FB1725660967Bdb9a014e36545',
  '0x9A4cc410Dc584C81EA1796b42E8D8Aa04cAb13Cf',
]
