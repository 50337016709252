/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef } from 'react'
import Web3 from 'web3'
import BN from 'bn.js'
import { useQuery } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { css } from '@emotion/react'
import { InjectedConnector } from '@web3-react/injected-connector'
import { Box, Button, ThemeProvider, Typography, Grid, useTheme } from '@material-ui/core'
import { shortenAddress } from './utils'
import { blootTheme as theme } from './theme'
import { Link } from 'react-router-dom'

import { AlphaUniversalButton } from '@alphafinance/react.ui-universalbutton.app-button'

import Gem from './Gem'

import './Bloot.css'

const GemABI = require('./GemABI.json')
const CrafterABI = require('./CrafterABI.json')
const BlootABI = require('./LootABI.json')

const injected = new InjectedConnector({ supportedChainIds: [1, 42] })

const MAX_UINT = new BN('115792089237316195423570985008687907853269984665640564039457584007913129639935')
const GEM_COUNT = 10

const CRAFTER_CONTRACTS: any = {
  1: '0x5B364B290399C7F4274304d06D31B7a891873675',
  42: '0x145142a903Ce1b5DC0d1987ae565b9692E0509d5',
}

const OLD_CRAFTER_CONTRACTS: any = {
  1: '0x6A509005cc88D7BA933a6A2849192D90A5b2E682',
  42: '0x0c283bCaFB9a47cE70bBbB7B624C909Ab8733875',
}

const GEM_CONTRACTS: any = {
  1: '0xC67DED0eC78b849e17771b2E8a7e303B4dAd6dD4',
  42: '0x18b8B3Ce8c8E44de201b6521e89328Fe07Ede4bB',
}

const OLD_GEM_CONTRACTS: any = {
  1: '0x046427fA6b924739Cd98Ee507CB0db34c7A66C2e',
  42: '0x18b8B3Ce8c8E44de201b6521e89328Fe07Ede4bB',
}

const LOOT_CONTRACTS: any = {
  1: '0x4f8730e0b32b04beaa5757e5aea3aef970e5b613',
  42: '0xC125C698167666Dfe8EBC856863591A72de4fb70',
}

const EXPLORERS: any = {
  1: 'https://etherscan.io/',
  42: 'https://kovan.etherscan.io/',
}

const FIRST_KIND = 10

const GEM_NAMES = (kind: any) => {
  const names = [
    'Violet Useless Rock of ALPHA',
    'Goldy Pebble of LOOKS RARE',
    'Translucent River Rock of HODL',
    'Blue Ice Scrap of UP ONLY',
    'Blushing Rock of PROBABLY NOTHING',
    'Mossy Riverside Pebble of LFG',
    'The Lovely Rock of GOAT',
    '#00FF00 of OG',
    '#0000FF of WAGMI',
    '#FF0000 of THE MOON',
  ]
  return names[parseInt(kind) - FIRST_KIND]
}

const GEMS_PER_MINES = (kind: any) => {
  const gemPerMines = [64, 32, 16, 8, 4, 2, 1, 1, 1, 1]
  return gemPerMines[parseInt(kind) - FIRST_KIND]
}

const GEM_COLORS = (kind: any) => {
  const colors = [
    '#9966CC',
    '#FFC87C',
    '#A8C3BC',
    '#0F52BA',
    '#E0115F',
    '#50C878',
    '#FC74E4',
    '#00FF00',
    '#0000FF',
    '#FF0000',
  ]

  return colors[parseInt(kind) - FIRST_KIND]
}

const getRanHex = (size: number) => {
  let result = []
  let hexRef = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f']
  for (let n = 0; n < size; n++) {
    result.push(hexRef[Math.floor(Math.random() * 16)])
  }
  return result.join('')
}

function luck(
  web3: Web3,
  chainId: string,
  entropy: string,
  gemAddr: string,
  senderAddr: string,
  kind: string,
  nonce: string,
  salt: BN,
): BN {
  return new BN(
    (
      web3.utils.soliditySha3(
        { t: 'uint256', v: chainId }, // chainid
        { t: 'bytes32', v: entropy },
        { t: 'address', v: gemAddr }, // gem address
        { t: 'address', v: senderAddr }, // sender address
        { t: 'uint', v: kind }, // gem kind
        { t: 'uint', v: nonce }, // sender nonce
        { t: 'uint', v: salt }, // sender salt
      ) as string
    ).slice(2),
    16,
  )
}

function MyGems() {
  const { account, library, chainId } = useWeb3React()
  const web3: Web3 = library
  const { data, isLoading } = useQuery('my gems', async () => {
    const chainId = await web3.eth.getChainId()
    const gem = new web3.eth.Contract(GemABI, GEM_CONTRACTS[chainId])
    const oldGem = new web3.eth.Contract(GemABI, OLD_GEM_CONTRACTS[chainId])
    const myGems = await gem.methods
      .balanceOfBatch(
        Array.from({ length: 10 }, () => account),
        Array.from({ length: 10 }, (_, k) => k + FIRST_KIND),
      )
      .call()
    const supplyGems = await Promise.all(
      Array.from({ length: 10 }, (_, k) => k + FIRST_KIND).map(async (idx) => {
        return gem.methods.totalSupply(idx).call()
      }),
    )
    const supplyOldGems = await Promise.all(
      Array.from({ length: 10 }, (_, k) => k + FIRST_KIND).map(async (idx) => {
        return oldGem.methods.totalSupply(idx).call()
      }),
    )
    const crafterOldGems = await oldGem.methods
      .balanceOfBatch(
        Array.from({ length: 10 }, () => CRAFTER_CONTRACTS[chainId]),
        Array.from({ length: 10 }, (_, k) => k),
      )
      .call()

    const gemsPerMines = await Promise.all(
      Array.from({ length: 10 }, (_, k) => k + FIRST_KIND).map(async (idx) => {
        return (await gem.methods.gems(idx).call()).gemsPerMine
      }),
    )
    const res = []
    for (let idx = 0; idx < 10; idx++) {
      res.push([
        myGems[idx],
        parseInt(supplyGems[idx]) + parseInt(supplyOldGems[idx]) - parseInt(crafterOldGems[idx]),
        gemsPerMines[idx],
      ])
    }
    return res
  })

  return (
    <Box mt={3} display="flex" flexDirection="column" alignItems="flex-start">
      <Typography variant="h5">Gems</Typography>
      <Typography variant="body1" color="textSecondary">
        There are various gems you can find, the gems below are sorted by the rarity from lowest to highest.
      </Typography>
      <br />

      {isLoading ? (
        'Loading Gems...'
      ) : (
        <>
          <Grid
            container
            spacing={2}
            css={css`
              margin-bottom: 0px !important;
            `}
          >
            <Grid item xs={4} md={3}>
              <Typography
                variant="body1"
                css={css`
                  font-weight: 600 !important;
                `}
              >
                Type
              </Typography>
            </Grid>
            <Grid item xs={4} md={2}>
              <Typography
                variant="body1"
                align="center"
                css={css`
                  font-weight: 600 !important;
                `}
              >
                Current Supply
              </Typography>
            </Grid>
            <Grid item xs={4} md={2}>
              <Typography
                variant="body1"
                align="center"
                css={css`
                  font-weight: 600 !important;
                `}
              >
                Gem(s) Per Mine
              </Typography>
            </Grid>
          </Grid>
          {(data as any).map(([count, supply, gpm]: string[], kind: number) => (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} md={3}>
                <Box display="flex" alignItems="center">
                  <Box
                    css={css`
                      margin-right: 10px;
                      padding-top: 4px;
                    `}
                  >
                    <Gem fill={GEM_COLORS(kind + FIRST_KIND)} />
                  </Box>
                  <Typography
                    variant="body1"
                    css={css`
                      color: ${GEM_COLORS(kind + FIRST_KIND)};
                      a {
                        color: ${GEM_COLORS(kind + FIRST_KIND)};
                        text-decoration: none;
                      }
                    `}
                  >
                    <a
                      href={`https://opensea.io/assets/${GEM_CONTRACTS[chainId]}/${kind + FIRST_KIND}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {GEM_NAMES(kind + FIRST_KIND)}
                    </a>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography variant="body1" align="center">
                  {supply}
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography variant="body1" align="center">
                  {gpm}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </>
      )}
    </Box>
  )
}

function MyBloot({ nftid }: { nftid: string }) {
  const { account, library } = useWeb3React()
  const web3: Web3 = library
  const theme = useTheme()
  const { data, isLoading } = useQuery('loot info ' + nftid, async () => {
    try {
      const crafter = new web3.eth.Contract(CrafterABI, CRAFTER_CONTRACTS[await web3.eth.getChainId()])
      const airdrops = await crafter.methods.airdrop(nftid).call()
      const claimed = await crafter.methods.claimed(nftid).call()
      return [airdrops, claimed]
    } catch (e) {
      return ['', '']
    }
  })

  const getGemList = (data: any) => {
    const list = []
    for (const gemIdx of data) {
      list.push(
        <Box
          display="flex"
          alignItems="center"
          css={css`
            margin-right: 8px;
          `}
        >
          <Gem fill={GEM_COLORS(gemIdx)} />{' '}
          <Typography
            variant="body1"
            css={css`
              color: ${GEM_COLORS(gemIdx)};
            `}
          >
            {GEM_NAMES(gemIdx)} x{GEMS_PER_MINES(gemIdx)}
          </Typography>
        </Box>,
      )
    }

    return (
      <Box display="flex" flexWrap="wrap">
        {list}
      </Box>
    )
  }
  return (
    <Box display="flex" alignItems=" center">
      {isLoading ? (
        'Loading...'
      ) : (
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Typography
              variant="body1"
              css={css`
                margin-right: 16px;
              `}
            >
              #{nftid}
              {' : '}
            </Typography>
            {getGemList((data as any)[0])}
          </Box>
          {(data as any)[1] ? (
            <Typography
              variant="body1"
              color="textSecondary"
              css={css`
                margin-left: 16px !important;
                ${theme.breakpoints.down('xs')} {
                  margin-top: 4px !important;
                  margin-bottom: 4px !important;
                  text-align: right !important;
                  flex: 1;
                }
              `}
            >
              Claimed
            </Typography>
          ) : (
            <Typography
              variant="body1"
              css={css`
                margin-left: 16px !important;
                text-decoration: underline;
                cursor: pointer;
                ${theme.breakpoints.down('xs')} {
                  margin-top: 4px !important;
                  margin-bottom: 4px !important;
                  text-align: right !important;
                  flex: 1;
                }
              `}
            >
              <span
                onClick={async () => {
                  const crafter = new web3.eth.Contract(CrafterABI, CRAFTER_CONTRACTS[await web3.eth.getChainId()])
                  crafter.methods.claim(nftid).send({ from: account })
                }}
              >
                Claim
              </span>
            </Typography>
          )}
        </Box>
      )}
    </Box>
  )
}

function MyBackpack({ isOld = false }) {
  const { account, library } = useWeb3React()
  const web3: Web3 = library
  const { data, isLoading } = useQuery(`my backpack ${isOld}`, async () => {
    const chainId = await web3.eth.getChainId()
    const gemContractAddress = isOld ? OLD_GEM_CONTRACTS[chainId] : GEM_CONTRACTS[chainId]
    const gem = new web3.eth.Contract(GemABI, gemContractAddress)
    const myGems = await gem.methods
      .balanceOfBatch(
        Array.from({ length: 10 }, () => account),
        Array.from({ length: 10 }, (_, k) => k + FIRST_KIND),
      )
      .call()
    const res = []
    for (let idx = 0; idx < 10; idx++) {
      res.push(parseInt(myGems[idx]))
    }
    return res
  })
  const handleMigrate = async () => {
    if (!isLoading && data?.length) {
      const chainId = await web3.eth.getChainId()
      const oldGem = new web3.eth.Contract(GemABI, OLD_GEM_CONTRACTS[chainId])

      await oldGem.methods
        .safeBatchTransferFrom(
          account,
          CRAFTER_CONTRACTS[chainId],
          Array.from({ length: 10 }, (_, k) => k)
            .filter((idx) => data[idx] !== 0)
            .map((x) => x + FIRST_KIND),
          data.filter((val) => val !== 0),
          '0x',
        )
        .send({ from: account })
    }
  }

  if (isOld && data?.filter((x) => x !== 0).length === 0) {
    return null
  }

  return (
    <Box display="flex" flexDirection="column" mt={3}>
      <Typography variant="h5">{isOld ? 'Old Backpack' : 'Backpack'}</Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        css={css`
          margin-bottom: 8px !important;
        `}
      >
        {isOld ? 'Your old gems will be showing here, please migrate them.' : 'Your gems will be showing here.'}
      </Typography>
      {isLoading ? (
        <Typography variant="body1">Loading ...</Typography>
      ) : data?.filter((x) => x !== 0).length === 0 ? (
        !isOld && (
          <Typography variant="body1" color="textPrimary">
            You don't have any gems, start mining to get gems!
          </Typography>
        )
      ) : (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          {isOld && (
            <Box mb={2}>
              <Button onClick={handleMigrate} size="small" variant="contained">
                Migrate
              </Button>
            </Box>
          )}
          {data?.map(
            (amount, idx) =>
              amount !== 0 && (
                <Box
                  display="flex"
                  alignItems="center"
                  css={css`
                    margin-right: 8px;
                  `}
                >
                  <Gem fill={GEM_COLORS(idx + FIRST_KIND)} />{' '}
                  <Typography
                    variant="body1"
                    css={css`
                      color: ${GEM_COLORS(idx + FIRST_KIND)};
                    `}
                  >
                    {GEM_NAMES(idx + FIRST_KIND)} x{amount}
                  </Typography>
                </Box>
              ),
          )}
        </Box>
      )}
    </Box>
  )
}
function MyBloots() {
  const { account, library } = useWeb3React()
  const web3: Web3 = library
  const { data, isLoading } = useQuery('my loots', async () => {
    const loot = new web3.eth.Contract(BlootABI, LOOT_CONTRACTS[await web3.eth.getChainId()])
    const crafter = new web3.eth.Contract(CrafterABI, CRAFTER_CONTRACTS[await web3.eth.getChainId()])
    const count = await loot.methods.balanceOf(account).call()
    return [
      await Promise.all(
        Array.from({ length: count }, (_, k) => k).map(async (idx) => {
          const nftid = await loot.methods.tokenOfOwnerByIndex(account, idx).call()
          const claimed = await crafter.methods.claimed(nftid).call()
          return [nftid, claimed]
        }),
      ),
      await web3.eth.getChainId(),
    ]
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [myBloots, chainid] = isLoading ? ['', ''] : (data as any)
  const claimableIds = isLoading
    ? []
    : myBloots.filter(([_, claimed]: any[]) => !claimed).map(([nftid]: any[]) => nftid)

  return (
    <Box mt={3}>
      <>
        <Box display="flex" flexDirection="column" mb={1}>
          <Typography variant="h5">My Bloots </Typography>
          <Typography variant="body1" color="textSecondary">
            Bloot holders can claim Welcome Pack gems here.
          </Typography>
          {/* <Typography variant="body1">Count = {myBloots.length}</Typography> */}
          {/* {chainid == 42 ? (
            <a href={'https://kovan.etherscan.io/address/' + LOOT_CONTRACTS[42] + '#writeContract'} target="_blank">
              GET FREE LOOTS!
            </a>
          ) : null} */}
        </Box>

        {isLoading ? (
          <Typography variant="body1">Loading My Bloots ...</Typography>
        ) : myBloots.length === 0 ? (
          <Typography variant="body1">You don't have any Bloot :(</Typography>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="flex-start" flexWrap="wrap" pt={2}>
            {claimableIds.length === 0 ? null : (
              <>
                <Button
                  variant="contained"
                  size="small"
                  onClick={async () => {
                    const crafter = new web3.eth.Contract(CrafterABI, CRAFTER_CONTRACTS[await web3.eth.getChainId()])
                    crafter.methods.multiClaim(claimableIds).send({ from: account })
                  }}
                >
                  Claim All
                </Button>
                <Box mt={1} mb={1}>
                  <Typography variant="body1">Or</Typography>
                </Box>
              </>
            )}

            {myBloots.map(([nftid]: string[]) => (
              <MyBloot key={nftid} nftid={nftid}></MyBloot>
            ))}
          </Box>
        )}
      </>
    </Box>
  )
}

function DoMine({ kind }: { kind: string }) {
  const { account, library } = useWeb3React()
  const lastInterval = useRef<any>(null)
  const [result, setResult] = useState('')

  const web3: Web3 = library
  const { data, isLoading } = useQuery('do mine ' + (parseInt(kind) + FIRST_KIND), async () => {
    const chainid = await web3.eth.getChainId()
    const gem = new web3.eth.Contract(GemABI, GEM_CONTRACTS[chainid])
    const { difficulty, entropy } = await gem.methods.gems(parseInt(kind) + FIRST_KIND).call()
    const nonce = await gem.methods.nonce(account).call()
    return [difficulty, nonce, chainid, entropy]
  })
  const [count, setCount] = useState(0)
  const [difficulty, nonce, chainid, entropy] = isLoading ? ['', '', ''] : (data as any)

  const startMine = () => {
    if (lastInterval.current) {
      stopMine()
    }
    lastInterval.current = setInterval(mine, 1)
    setCount(0)
    setResult('')
  }

  const stopMine = () => {
    if (lastInterval.current) {
      clearInterval(lastInterval.current)
    }
  }

  const mine = () => {
    const s = new BN(getRanHex(50), 16)
    const l = luck(
      web3,
      chainid,
      entropy,
      GEM_CONTRACTS[chainid],
      account as string,
      (parseInt(kind) + FIRST_KIND).toString(),
      nonce,
      s,
    )
    const t = MAX_UINT.div(new BN(difficulty))
    if (l.lte(t)) {
      setResult(s.toString())
      stopMine()
    }
    setCount((prevCount) => prevCount + 1)
  }

  useEffect(() => {
    stopMine()
  }, [kind])

  return (
    <Box display="flex" flexDirection="column" mt={1}>
      {isLoading ? (
        'Loading....'
      ) : (
        <div>
          <Typography variant="body1">Difficulty: {difficulty} </Typography>
          <Typography variant="body1">Nonce: {nonce} </Typography>
          <Typography variant="body1">Result : {result}</Typography>
          <Typography variant="body1">Epoch : {count}</Typography>
          <Box mt={2}>
            {result === '' ? (
              <>
                {' '}
                <Button
                  size="small"
                  variant="contained"
                  onClick={startMine}
                  css={css`
                    margin-right: 16px !important;
                  `}
                >
                  Start
                </Button>
                <Button size="small" variant="contained" onClick={stopMine}>
                  Stop
                </Button>{' '}
              </>
            ) : (
              <>
                <Typography
                  variant="body1"
                  css={css`
                    color: #50c878;
                  `}
                >
                  Found!!!
                </Typography>
                <Typography
                  variant="body1"
                  css={css`
                    margin-bottom: 8px !important;
                  `}
                >
                  Mine the {GEM_NAMES(kind)} by clicking MINE button below.
                </Typography>
                <Button
                  variant="contained"
                  size="small"
                  onClick={async () => {
                    const gem = new web3.eth.Contract(GemABI, GEM_CONTRACTS[await web3.eth.getChainId()])
                    gem.methods.mine(parseInt(kind) + FIRST_KIND, result).send({ from: account })
                  }}
                  css={css`
                    margin-right: 16px !important;
                  `}
                >
                  Mine
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setResult('')
                    setCount(0)
                  }}
                >
                  Reset
                </Button>
              </>
            )}
          </Box>
        </div>
      )}
    </Box>
  )
}

function Mining() {
  const { library } = useWeb3React()
  const web3: Web3 = library
  const [kind, setKind] = useState('0')

  const { data, isLoading } = useQuery('entropies', async () => {
    const chainid = await web3.eth.getChainId()
    const gem = new web3.eth.Contract(GemABI, GEM_CONTRACTS[chainid])
    const results = await Promise.all(
      Array.from({ length: GEM_COUNT }).map((_, idx) => gem.methods.gems(idx + GEM_COUNT).call()),
    )

    return results.map((r) => r.entropy)
  })

  const entropies = data || Array.from({ length: GEM_COUNT }).map((x) => '0x00')

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" mt={3}>
      <Typography variant="h5">Mining</Typography>
      <Typography variant="body1" color="textSecondary">
        Just for fun. For serious mining you will need dedicated programs and machines.
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        css={css`
          margin-bottom: 8px !important;
        `}
      >
        After you mine, please wait for the tx to confirm before starting mining again
      </Typography>
      <Box display="flex">
        <Typography
          variant="body1"
          css={css`
            margin-right: 16px !important;
          `}
        >
          Select Gem :{' '}
        </Typography>
        <select onChange={async (e) => setKind(e.target.value as string)}>
          {Array.from({ length: GEM_COUNT }, (_, k) => k)
            .filter((k) => !new BN(entropies[k].slice(2), 16).eq(new BN(0)))
            .map((kind) => (
              <option key={kind} value={kind}>
                {GEM_NAMES(kind + GEM_COUNT)}
              </option>
            ))}
        </select>
      </Box>
      <div>
        <DoMine kind={kind} />
      </div>
    </Box>
  )
}

function Main() {
  const { active, activate, account, chainId } = useWeb3React()
  const theme = useTheme()
  useEffect(() => {
    activate(injected)
  }, [activate])
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 50px;
        padding-bottom: 0px;
        max-width: 1280px;
        min-height: 90vh;
        margin: auto;
        ${theme.breakpoints.down('xs')} {
          padding: 24px;
        }
      `}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        css={css`
          max-width: 1280px;
        `}
      >
        <Box
          display="flex"
          alignItems="baseline"
          css={css`
            display: flex;
            align-items: baseline;

            a {
              text-decoration: none;
              color: #8e8e8e;
            }
            ${theme.breakpoints.down('xs')} {
              flex-direction: column;
            }
          `}
        >
          <Typography variant="h5">Provably Rare Gem</Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          css={css`
            a {
              color: white;
            }
          `}
        >
          <Typography
            css={css`
              margin-right: 8px !important;
              cursor: pointer;
            `}
          >
            <Link to="/rarity">Rarity</Link>
          </Typography>
          <Typography>|</Typography>
          <Typography
            css={css`
              margin-left: 8px !important;
              margin-right: 8px !important;
              cursor: pointer;
            `}
          >
            <Link to="/loot">Loot</Link>
          </Typography>
          <Typography>|</Typography>
          <Typography
            css={css`
              margin-left: 8px !important;
              cursor: pointer;
            `}
          >
            <Link to="/bloot">Bloot</Link>
          </Typography>

          <Box ml={2}>
            <AlphaUniversalButton logoColor="light" />
          </Box>
        </Box>
      </Box>
      <div
        css={css`
          flex: 1;
          max-width: 1280px;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            flex-wrap: wrap;
            margin-bottom: 32px;
          `}
        >
          <span
            css={css`
              font-size: 48px;
              font-weight: 600;
              color: black;
              background-color: #01ff02;
            `}
          >
            Blootgems
          </span>
          <span
            css={css`
              color: rgb(239, 205, 84);
            `}
          >
            The smart contract is not audited yet. Use at your own risk.
          </span>
        </div>
        {active ? (
          <div>
            {/* {Date.now() < 1630945800 * 1000 && (
              <Typography
                variant="h6"
                css={css`
                  margin-bottom: 16px !important;
                `}
              >
                Mining starts in : <Countdown date={1630945800 * 1000} />
              </Typography>
            )} */}
            <Typography
              variant="body1"
              color="textSecondary"
              css={css`
                a {
                  color: white;
                  text-decoration: none;
                }
              `}
            >
              <a href={`${EXPLORERS[chainId]}address/${GEM_CONTRACTS[chainId]}`} target="_blank" rel="noreferrer">
                Gem Address : {shortenAddress(GEM_CONTRACTS[chainId])}
              </a>
            </Typography>
            {/* <Typography
              variant="body1"
              color="textSecondary"
              css={css`
                margin-bottom: 16px !important;
                a {
                  color: white;
                  text-decoration: none;
                }
              `}
            >
              <a href={`${EXPLORERS[chainId]}address/${CRAFTER_CONTRACTS[chainId]}`} target="_blank" rel="noreferrer">
                Crafter Address : {shortenAddress(CRAFTER_CONTRACTS[chainId])}
              </a>
            </Typography> */}
            <Typography>My address: {shortenAddress(account as string)}</Typography>
            <MyGems />
            <MyBackpack />
            <MyBackpack isOld />
            <MyBloots />
            <Mining />
          </div>
        ) : (
          <Box>
            <Typography
              variant="body1"
              css={css`
                margin-bottom: 16px !important;
              `}
            >
              Please connect to Ethereum Mainnet Network.
            </Typography>
            <Button onClick={() => activate(injected)}>Connect</Button>
          </Box>
        )}
      </div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 32px;
          margin-bottom: 32px;
        `}
      >
        <span
          css={css`
            color: white;
            font-weight: 600;
            a {
              color: white;
            }
          `}
        >
          Made with ❤️ by{' '}
          <a href="https://twitter.com/nomorebear" target="_blank" rel="noreferrer">
            @swit.eth
          </a>{' '}
          and{' '}
          <a href="https://twitter.com/AlphaVentureDAO" target="_blank" rel="noreferrer">
            @AlphaVentureDAO
          </a>
        </span>
        <div
          css={css`
            display: flex;
          `}
        >
          <a
            href={`https://discord.gg/xDk6enpGnM`}
            target="_blank"
            rel="noreferrer"
            css={css`
              margin-right: 16px;
            `}
          >
            Discord
          </a>

          <a href="https://opensea.io/collection/provably-rare-gem" target="_blank" rel="noreferrer">
            Opensea
          </a>
        </div>
      </div>
    </div>
  )
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Main />
    </ThemeProvider>
  )
}

export default App
