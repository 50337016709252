/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef } from 'react'
import Web3 from 'web3'
import BN from 'bn.js'
import { useQuery } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { css } from '@emotion/react'
import { InjectedConnector } from '@web3-react/injected-connector'

import { AlphaUniversalButton } from '@alphafinance/react.ui-universalbutton.app-button'

import { Box, Button, ThemeProvider, Typography, Grid, useTheme } from '@material-ui/core'
import { shortenAddress } from '../utils'
import { lootTheme as theme } from '../theme'
import { Link } from 'react-router-dom'

import Gem from '../Gem'
import { WrappedGemBackpack } from './WrapedGemBackpack'

import '../Loot.css'

import {
  EXPLORERS,
  GEM_COLORS,
  GEM_COUNT,
  GEM_NAMES,
  LOOT_CONTRACTS,
  GEMS_PER_MINES,
  GEM_CONTRACTS,
  MAX_UINT,
  CRAFTER_CONTRACTS,
  OLD_GEM_CONTRACTS,
  WRAPPED_GEM_CONTRACTS,
} from './constants'

const GemABI = require('../GemABI.json')
const CrafterABI = require('../CrafterABI.json')
const LootABI = require('../LootABI.json')

const injected = new InjectedConnector({ supportedChainIds: [1, 42, 250] })

const getRanHex = (size: number) => {
  let result = []
  let hexRef = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f']
  for (let n = 0; n < size; n++) {
    result.push(hexRef[Math.floor(Math.random() * 16)])
  }
  return result.join('')
}

function luck(
  web3: Web3,
  chainId: string,
  entropy: string,
  gemAddr: string,
  senderAddr: string,
  kind: string,
  nonce: string,
  salt: BN,
): BN {
  return new BN(
    (
      web3.utils.soliditySha3(
        { t: 'uint256', v: chainId }, // chainid
        { t: 'bytes32', v: entropy },
        { t: 'address', v: gemAddr }, // gem address
        { t: 'address', v: senderAddr }, // sender address
        { t: 'uint', v: kind }, // gem kind
        { t: 'uint', v: nonce }, // sender nonce
        { t: 'uint', v: salt }, // sender salt
      ) as string
    ).slice(2),
    16,
  )
}

function MyGems() {
  const { account, library, chainId } = useWeb3React()
  const web3: Web3 = library
  const { data, isLoading } = useQuery('my gems', async () => {
    const chainId = await web3.eth.getChainId()
    const gem = new web3.eth.Contract(GemABI, GEM_CONTRACTS[chainId])
    const myGems = await gem.methods
      .balanceOfBatch(
        Array.from({ length: GEM_COUNT }, () => account),
        Array.from({ length: GEM_COUNT }, (_, k) => k),
      )
      .call()
    const supplyGems = await Promise.all(
      Array.from({ length: GEM_COUNT }, (_, k) => k).map(async (idx) => {
        return gem.methods.totalSupply(idx).call()
      }),
    )

    const gemsPerMines = await Promise.all(
      Array.from({ length: GEM_COUNT }, (_, k) => k).map(async (idx) => {
        return (await gem.methods.gems(idx).call()).gemsPerMine
      }),
    )
    const res = []
    for (let idx = 0; idx < GEM_COUNT; idx++) {
      res.push([myGems[idx], parseInt(supplyGems[idx]), gemsPerMines[idx]])
    }
    return res
  })

  return (
    <Box mt={3} display="flex" flexDirection="column" alignItems="flex-start">
      <Typography variant="h5">Gems</Typography>
      <Typography variant="body1" color="textSecondary">
        There are various gems you can find, the gems below are sorted by the rarity from lowest to highest.
      </Typography>
      <br />

      {isLoading ? (
        'Loading Gems...'
      ) : (
        <>
          <Grid
            container
            spacing={2}
            css={css`
              margin-bottom: 0px !important;
            `}
          >
            <Grid item xs={4} md={2}>
              <Typography
                variant="body1"
                css={css`
                  font-weight: 600 !important;
                `}
              >
                Type
              </Typography>
            </Grid>
            <Grid item xs={4} md={2}>
              <Typography
                variant="body1"
                align="center"
                css={css`
                  font-weight: 600 !important;
                `}
              >
                Current Supply
              </Typography>
            </Grid>
            <Grid item xs={4} md={2}>
              <Typography
                variant="body1"
                align="center"
                css={css`
                  font-weight: 600 !important;
                `}
              >
                Gem(s) Per Mine
              </Typography>
            </Grid>
          </Grid>
          {(data as any).map(([count, supply, gpm]: string[], kind: number) => (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} md={2}>
                <Box display="flex" alignItems="center">
                  <Box
                    css={css`
                      margin-right: 10px;
                      padding-top: 4px;
                    `}
                  >
                    <Gem fill={GEM_COLORS[kind]} />
                  </Box>
                  <Typography
                    variant="body1"
                    css={css`
                      color: ${GEM_COLORS[kind]};
                      a {
                        color: ${GEM_COLORS[kind]};
                        text-decoration: none;
                      }
                    `}
                  >
                    {/* <a
                      href={`https://opensea.io/assets/${GEM_CONTRACTS[chainId]}/${kind}`}
                      target="_blank"
                      rel="noreferrer"
                    > */}
                    {GEM_NAMES[kind]}
                    {/* </a> */}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography variant="body1" align="center">
                  {supply}
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography variant="body1" align="center">
                  {gpm}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </>
      )}
    </Box>
  )
}

function MyLoot({ nftid }: { nftid: string }) {
  const { account, library } = useWeb3React()
  const web3: Web3 = library
  const theme = useTheme()
  const { data, isLoading } = useQuery('loot info ' + nftid, async () => {
    try {
      const crafter = new web3.eth.Contract(CrafterABI, CRAFTER_CONTRACTS[await web3.eth.getChainId()])
      const airdrops = await crafter.methods.airdrop(nftid).call()
      const claimed = await crafter.methods.claimed(nftid).call()
      return [airdrops, claimed]
    } catch (e) {
      return ['', '']
    }
  })

  const getGemList = (data: any) => {
    const list = []
    for (const gemIdx of data) {
      list.push(
        <Box
          display="flex"
          alignItems="center"
          css={css`
            margin-right: 8px;
          `}
        >
          <Gem fill={GEM_COLORS[gemIdx]} />{' '}
          <Typography
            variant="body1"
            css={css`
              color: ${GEM_COLORS[gemIdx]};
            `}
          >
            {GEM_NAMES[gemIdx]} x{GEMS_PER_MINES[gemIdx]}
          </Typography>
        </Box>,
      )
    }

    return (
      <Box display="flex" flexWrap="wrap">
        {list}
      </Box>
    )
  }
  return (
    <Box display="flex" alignItems=" center">
      {isLoading ? (
        'Loading...'
      ) : (
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Typography
              variant="body1"
              css={css`
                margin-right: 16px;
              `}
            >
              #{nftid}
              {' : '}
            </Typography>
            {getGemList((data as any)[0])}
          </Box>
          {(data as any)[1] ? (
            <Typography
              variant="body1"
              color="textSecondary"
              css={css`
                margin-left: 16px !important;
                ${theme.breakpoints.down('xs')} {
                  margin-top: 4px !important;
                  margin-bottom: 4px !important;
                  text-align: right !important;
                  flex: 1;
                }
              `}
            >
              Claimed
            </Typography>
          ) : (
            <Typography
              variant="body1"
              css={css`
                margin-left: 16px !important;
                text-decoration: underline;
                cursor: pointer;
                ${theme.breakpoints.down('xs')} {
                  margin-top: 4px !important;
                  margin-bottom: 4px !important;
                  text-align: right !important;
                  flex: 1;
                }
              `}
            >
              <span
                onClick={async () => {
                  const crafter = new web3.eth.Contract(CrafterABI, CRAFTER_CONTRACTS[await web3.eth.getChainId()])
                  crafter.methods.claim(nftid).send({ from: account })
                }}
              >
                Claim
              </span>
            </Typography>
          )}
        </Box>
      )}
    </Box>
  )
}

const MyBackpack = ({}) => {
  const { account, library } = useWeb3React()
  const web3: Web3 = library
  const { data, isLoading } = useQuery(`my backpack`, async () => {
    const chainId = await web3.eth.getChainId()
    const gemContractAddress = GEM_CONTRACTS[chainId]
    const gem = new web3.eth.Contract(GemABI, gemContractAddress)
    const myGems = await gem.methods
      .balanceOfBatch(
        Array.from({ length: GEM_COUNT }, () => account),
        Array.from({ length: GEM_COUNT }, (_, k) => k),
      )
      .call()
    const res = []
    for (let idx = 0; idx < 10; idx++) {
      res.push(parseInt(myGems[idx]))
    }
    return res
  })

  const handleWrap = async (idx) => {
    const chainId = await web3.eth.getChainId()
    const gemContractAddress = GEM_CONTRACTS[chainId]
    const wrappedGemContractAddress = WRAPPED_GEM_CONTRACTS[idx]
    const gem = new web3.eth.Contract(GemABI, gemContractAddress)
    await gem.methods.safeTransferFrom(account, wrappedGemContractAddress, idx, data[idx], '0x').send({ from: account })
  }

  return (
    <Box display="flex" flexDirection="column" mt={3}>
      <Typography variant="h5">{'Backpack'}</Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        css={css`
          margin-bottom: 8px !important;
        `}
      >
        {'Your gems will be showing here.'}
      </Typography>
      {isLoading ? (
        <Typography variant="body1">Loading ...</Typography>
      ) : data?.filter((x) => x !== 0).length === 0 ? (
        <Typography variant="body1" color="textPrimary">
          You don't have any gems, start mining to get gems!
        </Typography>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          {data?.map(
            (amount, idx) =>
              amount !== 0 && (
                <Box
                  display="flex"
                  alignItems="center"
                  css={css`
                    margin-right: 8px;
                  `}
                >
                  <Gem fill={GEM_COLORS[idx]} />{' '}
                  <Typography
                    variant="body1"
                    css={css`
                      color: ${GEM_COLORS[idx]};
                    `}
                  >
                    {GEM_NAMES[idx]} x{amount}
                  </Typography>
                  <Typography
                    variant="body1"
                    onClick={() => handleWrap(idx)}
                    css={css`
                      margin-left: 16px !important;
                      cursor: pointer;
                    `}
                  >
                    <u>Wrap</u>
                  </Typography>
                </Box>
              ),
          )}
        </Box>
      )}
    </Box>
  )
}
function MyLoots() {
  const { account, library } = useWeb3React()
  const web3: Web3 = library
  const { data, isLoading } = useQuery('my loots', async () => {
    const loot = new web3.eth.Contract(LootABI, LOOT_CONTRACTS[await web3.eth.getChainId()])
    const crafter = new web3.eth.Contract(CrafterABI, CRAFTER_CONTRACTS[await web3.eth.getChainId()])
    const count = await loot.methods.balanceOf(account).call()
    return [
      await Promise.all(
        Array.from({ length: count }, (_, k) => k).map(async (idx) => {
          const nftid = await loot.methods.tokenOfOwnerByIndex(account, idx).call()
          const claimed = await crafter.methods.claimed(nftid).call()
          return [nftid, claimed]
        }),
      ),
      await web3.eth.getChainId(),
    ]
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [myLoots, chainid] = isLoading ? ['', ''] : (data as any)
  const claimableIds = isLoading ? [] : myLoots.filter(([_, claimed]: any[]) => !claimed).map(([nftid]: any[]) => nftid)

  return (
    <Box mt={3}>
      <>
        <Box display="flex" flexDirection="column" mb={1}>
          <Typography variant="h5">My Loots </Typography>
          <Typography variant="body1" color="textSecondary">
            Loot holders can claim Welcome Pack gems here.
          </Typography>
          {/* <Typography variant="body1">Count = {myLoots.length}</Typography> */}
          {/* {chainid == 42 ? (
            <a href={'https://kovan.etherscan.io/address/' + LOOT_CONTRACTS[42] + '#writeContract'} target="_blank">
              GET FREE LOOTS!
            </a>
          ) : null} */}
        </Box>

        {isLoading ? (
          <Typography variant="body1">Loading My Loots ...</Typography>
        ) : myLoots.length === 0 ? (
          <Typography variant="body1">You don't have any Loot :(</Typography>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="flex-start" flexWrap="wrap" pt={2}>
            {claimableIds.length === 0 ? null : (
              <>
                <Button
                  variant="contained"
                  size="small"
                  onClick={async () => {
                    const crafter = new web3.eth.Contract(CrafterABI, CRAFTER_CONTRACTS[await web3.eth.getChainId()])
                    crafter.methods.multiClaim(claimableIds).send({ from: account })
                  }}
                >
                  Claim All
                </Button>
                <Box mt={1} mb={1}>
                  <Typography variant="body1">Or</Typography>
                </Box>
              </>
            )}

            {myLoots.map(([nftid]: string[]) => (
              <MyLoot key={nftid} nftid={nftid}></MyLoot>
            ))}
          </Box>
        )}
      </>
    </Box>
  )
}

function DoMine({ kind }: { kind: string }) {
  const { account, library } = useWeb3React()
  const lastInterval = useRef<any>(null)
  const [result, setResult] = useState('')

  const web3: Web3 = library
  const { data, isLoading } = useQuery('do mine ' + kind, async () => {
    const chainid = await web3.eth.getChainId()
    const gem = new web3.eth.Contract(GemABI, GEM_CONTRACTS[chainid])
    const { difficulty, entropy } = await gem.methods.gems(kind).call()
    const nonce = await gem.methods.nonce(account).call()
    return [difficulty, nonce, chainid, entropy]
  })
  const [count, setCount] = useState(0)
  const [difficulty, nonce, chainid, entropy] = isLoading ? ['', '', ''] : (data as any) || [0, 0, 0, 0]

  const startMine = () => {
    if (lastInterval.current) {
      stopMine()
    }
    lastInterval.current = setInterval(mine, 1)
    setCount(0)
    setResult('')
  }

  const stopMine = () => {
    if (lastInterval.current) {
      clearInterval(lastInterval.current)
    }
  }

  const mine = () => {
    const s = new BN(getRanHex(50), 16)
    const l = luck(web3, chainid, entropy, GEM_CONTRACTS[chainid], account as string, kind, nonce, s)
    const t = MAX_UINT.div(new BN(difficulty))
    if (l.lte(t)) {
      setResult(s.toString())
      stopMine()
    }
    setCount((prevCount) => prevCount + 1)
  }

  useEffect(() => {
    stopMine()
    setCount(0)
    setResult('')
  }, [kind])

  return (
    <Box display="flex" flexDirection="column" mt={1}>
      {isLoading ? (
        'Loading....'
      ) : (
        <div>
          <Typography variant="body1">Difficulty: {difficulty} </Typography>
          <Typography variant="body1">Nonce: {nonce} </Typography>
          <Typography variant="body1">Result : {result}</Typography>
          <Typography variant="body1">Epoch : {count}</Typography>
          <Box mt={2}>
            {result === '' ? (
              <>
                {' '}
                <Button
                  size="small"
                  variant="contained"
                  onClick={startMine}
                  css={css`
                    margin-right: 16px !important;
                  `}
                >
                  Start
                </Button>
                <Button size="small" variant="contained" onClick={stopMine}>
                  Stop
                </Button>{' '}
              </>
            ) : (
              <>
                <Typography
                  variant="body1"
                  css={css`
                    color: #50c878;
                  `}
                >
                  Found!!!
                </Typography>
                <Typography
                  variant="body1"
                  css={css`
                    margin-bottom: 8px !important;
                  `}
                >
                  Mine the {GEM_NAMES[parseInt(kind)]} by clicking MINE button below.
                </Typography>
                <Button
                  variant="contained"
                  size="small"
                  onClick={async () => {
                    const gem = new web3.eth.Contract(GemABI, GEM_CONTRACTS[await web3.eth.getChainId()])
                    gem.methods.mine(kind, result).send({ from: account })
                  }}
                  css={css`
                    margin-right: 16px !important;
                  `}
                >
                  Mine
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setResult('')
                    setCount(0)
                  }}
                >
                  Reset
                </Button>
              </>
            )}
          </Box>
        </div>
      )}
    </Box>
  )
}

function Mining() {
  const { library } = useWeb3React()
  const web3: Web3 = library

  const [kind, setKind] = useState('0')

  const { data, isLoading } = useQuery('entropies', async () => {
    const chainid = await web3.eth.getChainId()
    const gem = new web3.eth.Contract(GemABI, GEM_CONTRACTS[chainid])
    const results = await Promise.all(Array.from({ length: GEM_COUNT }).map((_, idx) => gem.methods.gems(idx).call()))
    return results.map((r) => r.entropy)
  })

  const entropies = data || Array.from({ length: GEM_COUNT }).map((x) => '0x00')

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" mt={3}>
      <Typography variant="h5">Mining</Typography>
      <Typography variant="body1" color="textSecondary">
        Just for fun. For serious mining you will need dedicated programs and machines.
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        css={css`
          margin-bottom: 8px !important;
        `}
      >
        After you mine, please wait for the tx to confirm before starting mining again
      </Typography>
      <Box display="flex">
        <Typography
          variant="body1"
          css={css`
            margin-right: 16px !important;
          `}
        >
          Select Gem :{' '}
        </Typography>
        <select onChange={async (e) => setKind(e.target.value as string)}>
          {Array.from({ length: GEM_COUNT }, (_, k) => k)
            .filter((k) => !new BN(entropies[k].slice(2), 16).eq(new BN(0)))
            .map((kind) => (
              <option key={kind} value={kind}>
                {GEM_NAMES[kind]}
              </option>
            ))}
        </select>
      </Box>
      <div>
        <DoMine kind={kind} />
      </div>
    </Box>
  )
}

function Main() {
  const { active, activate, account, chainId } = useWeb3React()
  const theme = useTheme()
  useEffect(() => {
    activate(injected)
    const networkData = [
      {
        chainId: '0xFA',
        chainName: 'Fantom Opera',
        rpcUrls: ['https://rpc.ftm.tools'],
        nativeCurrency: {
          name: 'Fantom',
          symbol: 'FTM',
          decimals: 18,
        },
        blockExplorerUrls: ['https://ftmscan.com/'],
      },
    ]
    const Window = window as any
    if (Window.ethereum) {
      Window.ethereum.request({ method: 'wallet_addEthereumChain', params: networkData })
    }
  }, [activate])

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 50px;
        padding-bottom: 0px;
        max-width: 1280px;
        min-height: 90vh;
        margin: auto;
        ${theme.breakpoints.down('xs')} {
          padding: 24px;
        }
      `}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        css={css`
          max-width: 1280px;
        `}
      >
        <Box
          css={css`
            display: flex;
            align-items: baseline;
            a {
              text-decoration: none;
              color: #8e8e8e;
            }
            ${theme.breakpoints.down('xs')} {
              flex-direction: column;
            }
          `}
        >
          <Typography variant="h5">Provably Rare Gem</Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          css={css`
            a {
              color: white;
            }
          `}
        >
          <Typography
            variant="body1"
            css={css`
              margin-right: 40px !important;
              cursor: pointer;
              a {
                color: white;
              }
            `}
          >
            <a href="https://summoner-market.alphafinance.io/" target="_blank" rel="noreferrer">
              Summoner Marketplace
            </a>
          </Typography>

          <Typography
            css={css`
              margin-right: 8px !important;
              cursor: pointer;
            `}
          >
            <Link to="/rarity">Rarity</Link>
          </Typography>
          <Typography>|</Typography>
          <Typography
            css={css`
              margin-left: 8px !important;
              margin-right: 8px !important;
              cursor: pointer;
            `}
          >
            <Link to="/loot">Loot</Link>
          </Typography>
          <Typography>|</Typography>
          <Typography
            css={css`
              margin-left: 8px !important;
              cursor: pointer;
            `}
          >
            <Link to="/bloot">Bloot</Link>
          </Typography>

          <Box ml={2}>
            <AlphaUniversalButton logoColor="light" />
          </Box>
        </Box>
      </Box>
      <div
        css={css`
          flex: 1;
          max-width: 1280px;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            flex-wrap: wrap;
            margin-bottom: 32px;
            a {
              color: white;
              text-decoration: none;
            }
          `}
        >
          <span
            css={css`
              font-size: 48px;
              font-weight: 600;
            `}
          >
            Raritygems
          </span>

          <span
            css={css`
              color: rgb(239, 205, 84);
            `}
          >
            The smart contract is not audited yet. Use at your own risk.
          </span>
        </div>
        {active ? (
          <div>
            {/* {Date.now() < 1630945800 * 1000 && (
              <Typography
                variant="h6"
                css={css`
                  margin-bottom: 16px !important;
                `}
              >
                Mining starts in : <Countdown date={1630945800 * 1000} />
              </Typography>
            )} */}
            <Typography
              variant="body1"
              color="textSecondary"
              css={css`
                a {
                  color: white;
                  text-decoration: none;
                }
              `}
            >
              <a href={`${EXPLORERS[chainId]}address/${GEM_CONTRACTS[chainId]}`} target="_blank" rel="noreferrer">
                Gem Address : {shortenAddress(GEM_CONTRACTS[chainId])}
              </a>
            </Typography>
            {/* <Typography
              variant="body1"
              color="textSecondary"
              css={css`
                margin-bottom: 16px !important;
                a {
                  color: white;
                  text-decoration: none;
                }
              `}
            >
              <a href={`${EXPLORERS[chainId]}address/${CRAFTER_CONTRACTS[chainId]}`} target="_blank" rel="noreferrer">
                Crafter Address : {shortenAddress(CRAFTER_CONTRACTS[chainId])}
              </a>
            </Typography> */}
            <Typography>My address: {shortenAddress(account as string)}</Typography>
            <MyGems />
            <MyBackpack />
            <WrappedGemBackpack />
            {/* <MyBackpack isOld /> */}
            {/* <MyLoots /> */}
            <Mining />
          </div>
        ) : (
          <Box>
            <Typography
              variant="body1"
              css={css`
                margin-bottom: 16px !important;
              `}
            >
              Please connect to Fantom Opera Network.
            </Typography>
            <Button onClick={() => activate(injected)}>Connect</Button>
          </Box>
        )}
      </div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 32px;
          margin-bottom: 32px;
        `}
      >
        <span
          css={css`
            color: white;
            font-weight: 600;
            a {
              color: white;
            }
          `}
        >
          Made with ❤️ by{' '}
          <a href="https://twitter.com/nomorebear" target="_blank" rel="noreferrer">
            @swit.eth
          </a>{' '}
          and{' '}
          <a href="https://twitter.com/AlphaVentureDAO" target="_blank" rel="noreferrer">
            @AlphaVentureDAO
          </a>
        </span>
        <div
          css={css`
            display: flex;
          `}
        >
          <a
            href={`https://discord.gg/xDk6enpGnM`}
            target="_blank"
            rel="noreferrer"
            css={css`
              margin-right: 16px;
            `}
          >
            Discord
          </a>

          <a href="https://opensea.io/collection/provably-rare-gem" target="_blank" rel="noreferrer">
            Opensea
          </a>
        </div>
      </div>
    </div>
  )
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Main />
    </ThemeProvider>
  )
}

export default App
