// @ts-nocheck
import React from 'react'

type Props = {
  fill: string
}

const Gem = ({ fill = 'black' }: Props) => {
  return (
    <svg id="Layer_1" x="0px" y="0px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080" width="20" height="20">
      <g>
        <polygon
          className="st0"
          fill={fill}
          points="679.25,58.27 400.75,58.27 203.82,255.2 203.82,824.8 400.75,1021.73 679.25,1021.73 876.18,824.8 
			876.18,255.2 	"
        />
        <g className="st1" opacity="0.3">
          <path
            d="M679.25,58.27h-278.5L203.82,255.2v569.6l196.93,196.93h278.5L876.18,824.8V255.2L679.25,58.27z M739.56,709.06
				l-116.9,116.9H457.34l-116.9-116.9V370.94l116.9-116.9h165.32l116.9,116.9V709.06z"
          />
        </g>
        <g>
          <g>
            <polygon
              className="st2"
              fill="none"
              strokeWidth="10"
              strokeMiterlimit="10"
              stroke="#ffffff"
              points="679.25,58.27 400.75,58.27 203.82,255.2 203.82,824.8 400.75,1021.73 679.25,1021.73 876.18,824.8 
					876.18,255.2 			"
            />
            <polygon
              fill={fill}
              className="st2"
              strokeWidth="10"
              strokeMiterlimit="10"
              stroke="#ffffff"
              points="622.66,254.04 457.34,254.04 340.44,370.94 340.44,709.06 457.34,825.96 622.66,825.96 
					739.56,709.06 739.56,370.94 			"
            />
            <line
              className="st2"
              strokeWidth="10"
              strokeMiterlimit="10"
              stroke="#ffffff"
              x1="400.75"
              y1="58.27"
              x2="457.34"
              y2="254.04"
            />
            <line
              className="st2"
              strokeWidth="10"
              strokeMiterlimit="10"
              stroke="#ffffff"
              x1="679.25"
              y1="58.27"
              x2="622.66"
              y2="254.04"
            />
            <line
              className="st2"
              strokeWidth="10"
              strokeMiterlimit="10"
              stroke="#ffffff"
              x1="203.82"
              y1="255.2"
              x2="340.44"
              y2="370.94"
            />
            <line
              className="st2"
              strokeWidth="10"
              strokeMiterlimit="10"
              stroke="#ffffff"
              x1="739.56"
              y1="370.94"
              x2="876.18"
              y2="255.2"
            />
            <line
              className="st2"
              strokeWidth="10"
              strokeMiterlimit="10"
              stroke="#ffffff"
              x1="739.56"
              y1="709.06"
              x2="876.18"
              y2="824.8"
            />
            <line
              className="st2"
              strokeWidth="10"
              strokeMiterlimit="10"
              stroke="#ffffff"
              x1="622.66"
              y1="825.96"
              x2="679.25"
              y2="1021.73"
            />
            <line
              className="st2"
              strokeWidth="10"
              strokeMiterlimit="10"
              stroke="#ffffff"
              x1="457.34"
              y1="825.96"
              x2="400.75"
              y2="1021.73"
            />
            <line
              className="st2"
              strokeWidth="10"
              strokeMiterlimit="10"
              stroke="#ffffff"
              x1="340.44"
              y1="709.06"
              x2="203.82"
              y2="824.8"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Gem
