/** @jsxImportSource @emotion/react */
import BigNumber from 'bignumber.js'
import Web3 from 'web3'
import { useQuery } from 'react-query'
import { Box, Typography } from '@material-ui/core'
import { useWeb3React } from '@web3-react/core'
import { css } from '@emotion/react'

import { GEM_NAMES, GEM_COLORS, WRAPPED_GEM_CONTRACTS, EXPLORERS } from './constants'
import Gem from '../Gem'

const WrappedGem = require('../WrappedGem.json')

export const WrappedGemBackpack = () => {
  const { account, library, chainId } = useWeb3React()
  const web3: Web3 = library
  const { data, isLoading } = useQuery(`wrapped backpack`, async () => {
    const requests = []

    for (let i = 0; i < WRAPPED_GEM_CONTRACTS.length; i++) {
      const wrappedGem = new web3.eth.Contract(WrappedGem, WRAPPED_GEM_CONTRACTS[i])
      requests.push(wrappedGem.methods.balanceOf(account).call())
    }

    const results = await Promise.all(requests)

    return results.map((r) => new BigNumber(r))
  })

  const handleUnwrap = async (idx) => {
    const wrappedGem = new web3.eth.Contract(WrappedGem, WRAPPED_GEM_CONTRACTS[idx])
    await wrappedGem.methods.redeem(data[idx].div('1e18').toFixed(0)).send({ from: account })
  }

  return (
    <Box display="flex" flexDirection="column" mt={3}>
      <Typography variant="h5">Wrapped Gems Backpack (ERC20)</Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        css={css`
          margin-bottom: 8px !important;
        `}
      >
        {`Your wrapped gems will be showing here.`}
      </Typography>
      {isLoading ? (
        <Typography variant="body1">Loading ...</Typography>
      ) : data?.filter((x) => !x.eq(0)).length === 0 ? (
        <Typography variant="body1" color="textPrimary">
          You don't have any wrapped gems.
        </Typography>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          {data?.map(
            (amount, idx) =>
              !amount.eq(0) && (
                <Box
                  display="flex"
                  alignItems="center"
                  css={css`
                    margin-right: 8px;
                  `}
                >
                  <a
                    href={`${EXPLORERS[chainId]}address/${WRAPPED_GEM_CONTRACTS[idx]}`}
                    target="_blank"
                    rel="noreferrer"
                    css={css`
                      display: flex;
                    `}
                  >
                    <Gem fill={GEM_COLORS[idx]} />{' '}
                    <Typography
                      variant="body1"
                      css={css`
                        color: ${GEM_COLORS[idx]};
                      `}
                    >
                      [Wrapped] {GEM_NAMES[idx]} x{amount.div('1e18').toFormat(2)}
                    </Typography>
                  </a>
                  <Typography
                    variant="body1"
                    onClick={() => handleUnwrap(idx)}
                    css={css`
                      margin-left: 16px !important;
                      cursor: pointer;
                    `}
                  >
                    <u>Unwrap</u>
                  </Typography>
                </Box>
              ),
          )}
        </Box>
      )}
    </Box>
  )
}
