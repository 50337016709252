import { createTheme, responsiveFontSizes } from '@material-ui/core'

export const lootTheme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: '#FFF',
      },
      secondary: {
        main: '#8e8e8e',
      },
      text: {
        primary: '#FFF',
        secondary: '#8e8e8e',
      },
    },
    typography: {
      fontFamily: "'EB Garamond', serif",
    },
    overrides: {
      MuiButton: {
        contained: {
          '&.Mui-disabled': {
            color: 'rgba(255,255,255, 0.5)',
          },
        },
      },
    },
  }),
)

export const blootTheme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: '#FFF',
      },
      secondary: {
        main: '#8e8e8e',
      },
      text: {
        primary: '#FFF',
        secondary: '#8e8e8e',
      },
    },
    typography: {
      fontFamily: "'EB Garamond', serif",
    },
    overrides: {
      MuiButton: {
        contained: {
          '&.Mui-disabled': {
            color: 'rgba(255,255,255, 0.5)',
          },
        },
      },
    },
  }),
)
