/** @jsxImportSource @emotion/react */
import Web3 from 'web3'
import { Web3ReactProvider } from '@web3-react/core'
import { QueryClient, QueryClientProvider } from 'react-query'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'

import './App.css'

import Loot from './Loot'
import Bloot from './Bloot'
import Rarity from './rarity/Rarity'

const queryClient = new QueryClient()

function getLibrary(provider: any) {
  return new Web3(provider)
}
function App() {
  return (
    <Router>
      <Web3ReactProvider getLibrary={getLibrary}>
        <QueryClientProvider client={queryClient}>
          <Switch>
            <Route path="/rarity">
              <Rarity />
            </Route>
            <Route path="/loot">
              <Loot />
            </Route>
            <Route path="/bloot">
              <Bloot />
            </Route>
            <Route path="/">
              <Loot />
            </Route>
          </Switch>
        </QueryClientProvider>
      </Web3ReactProvider>
    </Router>
  )
}

export default App
